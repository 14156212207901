"use strict";

const { default: Axios } = require("axios");
const Custombox = require("exports-loader?Custombox!../../node_modules/custombox/dist/custombox.min.js");
const { event } = require("jquery");
//global.Custombox = Custombox;

let remarkModal;
let el;
let data;

let load = () => {
    let remark_done_yes = document.getElementById("remark_done_yes");
    let remark_done_no = document.getElementById("remark_done_no");
    let remark_raison = document.getElementById("remark_raison");
    let remark_raison_id = document.getElementById("remark_raison_id");
    let remark_material = document.getElementById("remark_material");
    let arab_daily_study_check = document.getElementById("arab_daily_study_check");
    let quran_daily_study_check = document.getElementById("quran_daily_study_check");

    let arab_homework_check = document.getElementById("arab_homework_check");
    let quran_homework_check = document.getElementById("quran_homework_check");
    let quran_homework_review_check = document.getElementById("quran_homework_review_check");
    let quran_exam_check = document.getElementById("quran_exam_check");
    let arab_exam_check = document.getElementById("arab_exam_check");
    let arab_resultat_check = document.getElementById("arab_resultat_check");
    let quran_resultat_check = document.getElementById("quran_resultat_check");

    if (
        remark_done_yes &&
        remark_done_no &&
        remark_raison &&
        remark_raison_id
    ) {
        remark_done_yes.addEventListener("click", () => {
            // remark_raison_id.value = 0;
            remark_raison.classList.add("d-none-js");
            document.getElementById("appreciation_div").classList.remove('d-none');

            if (remark_material.value == "A") {
                document.getElementById("arabe_level_div").classList.remove('d-none');
            }
            else {
                document.getElementById("arabe_level_div").classList.add('d-none');
            }

            if (remark_material.value == "A") {
                document.getElementById("arab_options_div").classList.remove('d-none');
                document.getElementById("quran_options_div").classList.add('d-none');

                // document.getElementById("arabe_daily_study_div").classList.remove('d-none');
                // document.getElementById("arab_daily_review_div").classList.remove('d-none');

                // document.getElementById("quran_daily_study_div").classList.add('d-none');
                // document.getElementById("quran_daily_review_div").classList.add('d-none');
            }
            else if (remark_material.value == 'C') {
                document.getElementById("arab_options_div").classList.add('d-none');
                document.getElementById("quran_options_div").classList.remove('d-none');

                // document.getElementById("quran_daily_study_div").classList.remove('d-none');
                // document.getElementById("quran_daily_review_div").classList.remove('d-none');

                // document.getElementById("arabe_daily_study_div").classList.add('d-none');
                // document.getElementById("arab_daily_review_div").classList.add('d-none');
            }
            if (arab_homework_check.checked) {
                document.getElementById("arabic_homework_div").classList.remove('d-none');

                // document.getElementById("quran_daily_homework_div").classList.add('d-none');
                // document.getElementById("quran_daily_homework_review_div").classList.add('d-none');
            }
            else if (quran_homework_check.checked) {
                document.getElementById("quran_daily_homework_div").classList.remove('d-none');
                // document.getElementById("quran_daily_homework_review_div").classList.add('d-none');
                // document.getElementById("arabic_homework_div").classList.add('d-none');
            }
            else if (quran_homework_review_check.checked) {
                // document.getElementById("quran_daily_homework_div").classList.remove('d-none');
                document.getElementById("quran_daily_homework_review_div").classList.remove('d-none');

                // document.getElementById("arabic_homework_div").classList.add('d-none');
            }
            else {
                document.getElementById("quran_daily_homework_div").classList.add('d-none');
                document.getElementById("quran_daily_homework_review_div").classList.add('d-none');
                document.getElementById("arabic_homework_div").classList.add('d-none');
            }

            if (quran_exam_check.checked || arab_exam_check.checked) {
                document.getElementById("next_session_exam_div").classList.remove('d-none');
            }
            else {
                document.getElementById("next_session_exam_div").classList.add('d-none');
            }

            if (quran_resultat_check.checked) {
                document.getElementById("quran_exam_result_div").classList.remove('d-none');
                document.getElementById("success_div").classList.remove('d-none');
                document.getElementById("failed_div").classList.remove('d-none');
            }
            else if (arab_resultat_check.checked) {
                document.getElementById("arab_exam_result_div").classList.remove('d-none');
                document.getElementById("success_div").classList.remove('d-none');
                document.getElementById("failed_div").classList.remove('d-none');
            }
        });

        remark_done_no.addEventListener("click", () => {
            // remark_raison_id.value = 0;
            remark_raison.classList.remove("d-none-js");
            document.getElementById("arab_options_div").classList.add('d-none');
            document.getElementById("quran_options_div").classList.add('d-none');

            if (remark_material.value == "A") {
                document.getElementById("arabe_level_div").classList.add('d-none');
            }
            document.getElementById("appreciation_div").classList.add('d-none');

            if (remark_material.value == "A") {
                document.getElementById("arabe_daily_study_div").classList.add('d-none');
                document.getElementById("arab_daily_review_div").classList.add('d-none');
            }
            else if (remark_material.value == 'C') {
                document.getElementById("quran_daily_study_div").classList.add('d-none');
                document.getElementById("quran_daily_review_div").classList.add('d-none');
            }
            if (quran_homework_check.checked) {
                document.getElementById("arabic_homework_div").classList.add('d-none');
                document.getElementById("quran_daily_homework_div").classList.remove('d-none');
                document.getElementById("quran_daily_homework_review_div").classList.add('d-none');
            }
            else if (quran_homework_review_check.checked) {
                document.getElementById("quran_daily_homework_div").classList.add('d-none');
                document.getElementById("quran_daily_homework_review_div").classList.remove('d-none');
                document.getElementById("arabic_homework_div").classList.add('d-none');
            }
            else if (arab_homework_check.checked) {
                document.getElementById("quran_daily_homework_div").classList.add('d-none');
                document.getElementById("quran_daily_homework_review_div").classList.add('d-none');
                document.getElementById("arabic_homework_div").classList.remove('d-none');
            }
            if (quran_exam_check.checked || arab_exam_check.checked) {
                document.getElementById("next_session_exam_div").classList.add('d-none');
            }
            if (quran_resultat_check.checked) {
                document.getElementById("quran_exam_result_div").classList.add('d-none');
                document.getElementById("success_div").classList.add('d-none');
                document.getElementById("failed_div").classList.add('d-none');
            }
            else if (arab_resultat_check.checked) {
                document.getElementById("arab_exam_result_div").classList.add('d-none');
                document.getElementById("success_div").classList.add('d-none');
                document.getElementById("failed_div").classList.add('d-none');
            }
        });
    }
};

function showRemarkSection() {
    let remark_material = document.getElementById("remark_material");
    let arab_daily_study_check = document.getElementById("arab_daily_study_check");
    let quran_daily_study_check = document.getElementById("quran_daily_study_check");
    let arab_daily_review_check = document.getElementById("arab_daily_review_check");
    let quran_daily_review_check = document.getElementById("quran_daily_review_check");
    let arab_homework_check = document.getElementById("arab_homework_check");
    let quran_homework_check = document.getElementById("quran_homework_check");
    let quran_homework_review_check = document.getElementById("quran_homework_review_check");

    let quran_exam_check = document.getElementById("quran_exam_check");
    let arab_exam_check = document.getElementById("arab_exam_check");
    let arab_resultat_check = document.getElementById("arab_resultat_check");
    let quran_resultat_check = document.getElementById("quran_resultat_check");
    let remark_done_no = document.getElementById("remark_done_no");

    let arabe_daily_study_div = $("#arabe_daily_study_div");
    let quran_daily_study_div = $("#quran_daily_study_div");
    let arab_daily_review_div = $("#arab_daily_review_div");
    let quran_daily_review_div = $("#quran_daily_review_div");
    let arabic_homework_div = $("#arabic_homework_div");
    let quran_daily_homework_div = $("#quran_daily_homework_div");
    let quran_daily_homework_review_div = $("#quran_daily_homework_review_div");
    let quran_exam_div = $("#quran_exam_div");
    let arab_exam_div = $("#arab_exam_div");

    let arab_exam_result_div = $("#arab_exam_result_div");
    let quran_exam_result_div = $("#quran_exam_result_div");
    let success_div = $("#success_div");
    let failed_div = $("#failed_div");
    let arab_book_result = $("#arab_book_result");

    if (remark_material.value == "A") {
        document.getElementById("arab_options_div").classList.remove('d-none');
        document.getElementById("quran_options_div").classList.add('d-none');
        arab_book_result.removeClass("d-none");
    } else if (remark_material.value == "C") {
        document.getElementById("arab_options_div").classList.add('d-none');
        document.getElementById("quran_options_div").classList.remove('d-none');
        arab_book_result.addClass("d-none");
    }

    // console.log(check_devoir);

    if (arab_daily_study_check.checked) {
        arabe_daily_study_div.removeClass("d-none");
    }
    else {
        arabe_daily_study_div.addClass("d-none");
    }

    if (quran_daily_study_check.checked) {
        quran_daily_study_div.removeClass("d-none");
    }
    else {
        quran_daily_study_div.addClass("d-none");
    }

    if (arab_daily_review_check.checked) {
        arab_daily_review_div.removeClass("d-none");
    }
    else {
        arab_daily_review_div.addClass("d-none");
    }

    if (quran_daily_review_check.checked) {
        quran_daily_review_div.removeClass("d-none");
    }
    else {
        quran_daily_review_div.addClass("d-none");
    }

    if (arab_homework_check.checked) {
        arabic_homework_div.removeClass("d-none");
    }
    else {
        arabic_homework_div.addClass("d-none");
    }

    if (quran_homework_check.checked) {
        quran_daily_homework_div.removeClass("d-none");
    }
    else {
        quran_daily_homework_div.addClass("d-none");
    }
    if (quran_homework_review_check.checked) {
        quran_daily_homework_review_div.removeClass("d-none");
    }
    else {
        quran_daily_homework_review_div.addClass("d-none");
    }

    if (arab_exam_check.checked) {
        arab_exam_div.removeClass("d-none");
    } else {
        arab_exam_div.addClass("d-none");
    }

    if (quran_exam_check.checked) {
        quran_exam_div.removeClass('d-none');
    } else {
        quran_exam_div.addClass("d-none");
    }


    if (arab_resultat_check.checked) {
        quran_exam_result_div.addClass("d-none");

        arab_exam_result_div.removeClass("d-none");

        success_div.removeClass("d-none");
        failed_div.removeClass("d-none");
    } else if (quran_resultat_check.checked) {
        quran_exam_result_div.removeClass("d-none");

        arab_exam_result_div.addClass("d-none");

        success_div.removeClass("d-none");
        failed_div.removeClass("d-none");
    }
    else {
        quran_exam_result_div.addClass("d-none");
        arab_exam_result_div.addClass("d-none");
        success_div.addClass("d-none");
        failed_div.addClass("d-none");
    }
}

function loadModalSections() {
    let remark_material = document.getElementById("remark_material");

    // console.log(remark_material);
    if (!remark_material) {
        return;
    }

    if (remark_material.value == "A") {
        document.getElementById("number_level").innerText = 1;
        document.getElementById("number_appreci").innerText = 2;
    }
    else if (remark_material.value == "C") {
        document.getElementById("number_appreci").innerText = 1;
    }

    if (remark_material.value == "A") {
        document.getElementById("arab_options_div").classList.remove('d-none');
        document.getElementById("quran_options_div").classList.add('d-none');
    } else if (remark_material.value == "C") {
        document.getElementById("arab_options_div").classList.add('d-none');
        document.getElementById("quran_options_div").classList.remove('d-none');
    }

    var arabe_level_div = $("#arabe_level_div");

    if (remark_material.value == "A") {
        arabe_level_div.removeClass("d-none");
    } else if (remark_material.value == "C") {
        arabe_level_div.addClass("d-none");
    }
}

let open = (container, btn) => {
    // console.log($(this).data());
    data = btn.dataset;
    el = document.getElementById(container);
    if (el) {
        // console.log('Salam');
        //document.querySelector('#student_note').value = data.studentNote ;
        //document.querySelector('#note-student-name').innerHTML = data.studentName;
        el.classList.remove("d-none-js");

        // Instantiate new modal
        var modal = new Custombox.modal({
            content: {
                effect: "fadein",
                target: "#modalRemark",
            },
        });

        // Open
        modal.open();

        document.addEventListener("custombox:overlay:close", function () {
            // Overlay closed
            //document.querySelector('#student_note').value = '' ;
            el.classList.add("d-none-js");
        });
    }
};

let openTeacher = (container, material, btn) => {
    // console.log($(this).data());
    data = btn.dataset;
    el = document.getElementById(container);
    var remark_matiere = document.getElementById("remark_material");
    var student = document.getElementById("student_id");

    // console.log(data);
    if (remark_matiere) {
        remark_matiere.value = material;
    }
    if (student) {
        student.value = data.studentId;
    }
    if (el) {
        // console.log('Salam');
        //document.querySelector('#student_note').value = data.studentNote ;
        //document.querySelector('#note-student-name').innerHTML = data.studentName;
        el.classList.remove("d-none-js");

        // Instantiate new modal
        var modal = new Custombox.modal({
            content: {
                effect: "fadein",
                target: "#modalRemark",
            },
        });

        // Open
        modal.open();

        document.addEventListener("custombox:overlay:close", function () {
            // Overlay closed
            //document.querySelector('#student_note').value = '' ;
            el.classList.add("d-none-js");
        });

        // loadModalSections();
    }
};

var cancel = () => {
    //document.querySelector('#student_note').value = '' ;
    el.classList.add("d-none-js");
    Custombox.modal.close();
};

var save = (teacher = false) => {
    // Get done
    let done = document.querySelector(
        '#modalRemark input[name="done"]:checked'
    ).value;
    let note = document.querySelector(
        '#modalRemark textarea[name="remark_note"]'
    );
    if (note) {
        note = note.value;
    } else {
        note = null;
    }

    let raison = null;
    if (done == "no") {
        console.log("no");
        raison = document.querySelector(
            '#modalRemark select[name="remark_raison_id"]'
        ).value;
    } else {
        console.log("yes");
        raison = null;
    }
    if (done == "no" && raison == 0) return false;
    var req = {
        done: done,
        raison: raison,
        note: note,
        courseId: data.courseid,
        studentId: data.studentid,
        programType: data.programtype,
    };
    Axios.post(url_student_ajax_remark_add, req)
        .then(function (response) {
            console.log(response);
            Custombox.modal.close();
            el.classList.add("d-none-js");
            location.reload();
        }).catch(function () {
            el.classList.add("d-none-js");
        });
    return;
};

var SaveTeacher = () => {
    // Get done
    let done = document.querySelector(
        '#modalRemark input[name="done"]:checked'
    ).value;
    let note = document.querySelector(
        '#modalRemark textarea[name="remark_note"]'
    );
    if (note) {
        note = note.value;
    } else {
        note = null;
    }
    let remark_material = document.getElementById("remark_material").value;

    let check_devoir = document.getElementById("check_devoir").checked ? 1 : 0;
    let check_examen = document.getElementById("check_examen").checked ? 1 : 0;
    let check_resultat = document.getElementById("check_resultat").checked ? 1 : 0;
    let level = document.getElementById("level").value;
    let appreci = document.getElementById("appreci").value;
    let arab_daily_study_from = document.getElementById("arab_daily_study_from").value;
    let arab_daily_study_to = document.getElementById("arab_daily_study_to").value;
    let quran_daily_study_sourate = document.getElementById("quran_daily_study_sourate").value;
    let quran_daily_study_from = document.getElementById("quran_daily_study_from").value;
    let quran_daily_study_to = document.getElementById("quran_daily_study_to").value;
    let arab_daily_review_from = document.getElementById("arab_daily_review_from").value;
    let arab_daily_review_to = document.getElementById("arab_daily_review_to").value;
    let quran_daily_review_sourate = document.getElementById("quran_daily_review_sourate").value;
    let quran_daily_review_from = document.getElementById("quran_daily_review_from").value;
    let quran_daily_review_to = document.getElementById("quran_daily_review_to").value;
    let arab_daily_homework_from = document.getElementById("arab_daily_homework_from").value;
    let arab_daily_homework_to = document.getElementById("arab_daily_homework_to").value;
    let quran_daily_homework_memorisation_sourate = document.getElementById("quran_daily_homework_memorisation_sourate").value;
    let quran_daily_homework_memorisation_from = document.getElementById("quran_daily_homework_memorisation_from").value;
    let quran_daily_homework_memorisation_to = document.getElementById("quran_daily_homework_memorisation_to").value;
    let quran_daily_homework_review_sourate = document.getElementById("quran_daily_homework_review_sourate").value;
    let quran_daily_homework_review_from = document.getElementById("quran_daily_homework_review_from").value;
    let quran_daily_homework_review_to = document.getElementById("quran_daily_homework_review_to").value;
    let quran_result = document.getElementById("quran_result").value;
    let arab_result_oral = document.getElementById("arab_result_oral").value;
    let arab_result_write = document.getElementById("arab_result_write").value;
    let result_success = document.getElementById("result_success").checked ? 1 : 0;
    let result_failed = document.getElementById("result_failed").checked ? 1 : 0;

    let raison = null;
    if (done == "no") {
        console.log("no");
        raison = document.querySelector(
            '#modalRemark select[name="remark_raison_id"]'
        ).value;
    } else {
        console.log("yes");
        raison = null;
    }
    if (done == "no" && raison == 0) return false;
    // console.log('sending');
    Axios.post(url_teacher_ajax_remark_add, {
        done: done,
        raison: raison,
        note: note,
        material: remark_material,

        courseId: data.courseId,
        studentId: data.studentId,
        programType: data.programType,
        check_devoir: check_devoir,
        check_examen: check_examen,
        check_resultat: check_resultat,
        level: level,
        appreciation: appreci,
        daily_study_from: (remark_material == 'A') ? arab_daily_study_from : quran_daily_study_from,
        daily_study_to: (remark_material == 'A') ? arab_daily_study_to : quran_daily_study_to,
        daily_study_sourate: (quran_daily_study_sourate != '') ? quran_daily_study_sourate : '-',

        daily_review_sourate: quran_daily_review_sourate,
        daily_review_from: (remark_material == 'A') ? arab_daily_review_from : quran_daily_review_from,
        daily_review_to: (remark_material == 'A') ? arab_daily_review_to : quran_daily_review_to,

        home_work_sourate: (quran_daily_homework_memorisation_sourate != '') ? quran_daily_homework_memorisation_sourate : null,
        home_work_from: (remark_material == 'A') ? arab_daily_homework_from : quran_daily_homework_memorisation_from,
        home_work_to: (remark_material == 'A') ? arab_daily_homework_to : quran_daily_homework_memorisation_to,

        home_work_review_sourate: quran_daily_homework_review_sourate,
        home_work_review_from: (remark_material == 'C') ? quran_daily_homework_review_from : null,
        home_work_review_to: (remark_material == 'C') ? quran_daily_homework_review_to : null,

        is_next_session_exam: check_examen,

        is_exam_result: check_resultat,
        quran_exam_result: (quran_result != '') ? quran_result : 0,
        arabic_exam_read_result: (arab_result_oral != '') ? arab_result_oral : 0,
        arabic_exam_write_result: (arab_result_write != '') ? arab_result_write : 0,

        is_success: result_success,
        is_failed: result_failed,
    }).then(function (response) {
        console.log(response);
        Custombox.modal.close();
        el.classList.add("d-none-js");
        console.log('sent');
        location.reload();
    }).catch(function (rr) {
        console.log(rr);
        // location.reload();
    });
    return;
};

var OpenRemarkStudent = (modal_id, btn) => {
    data = btn.dataset;
    el = document.getElementById(modal_id);
    if (!el) {
        return;
    }

    el.classList.remove("d-none-js");

    // Instantiate new modal
    var modal = new Custombox.modal({
        content: {
            effect: "fadein",
            target: "#" + modal_id,
        },
    });

    // Open
    modal.open();

    document.addEventListener("custombox:overlay:close", function () {
        el.classList.add("d-none-js");
    });

    Axios.post(url_student_ajax_remark_get, {
        course_remark_id: data.remark
    }).then(function (response) {
        console.log(response.data);
        document.getElementById('appreciation_div').classList.remove('d-none');
        document.getElementById('appreciation').innerText = response.data.course_remark.appreciation;

        var homework_div = document.getElementById('homework_div');

        if (homework_div && response.data.course_remark.is_daily_home_work && response.data.material == 'A') {
            homework_div.classList.remove('d-none');
        }
        else if (homework_div) {
            homework_div.classList.add('d-none');
        }

        if (response.data.material == 'A') {
            if (response.data.course_remark.is_daily_study) {
                document.getElementById('arab_daily_study_div').classList.remove('d-none');
                var arab_daily = document.getElementById('arab_daily_study_data');
                arab_daily.innerHTML = '"<span class="text-blue-500 font-bold">' + response.data.course_remark.arabic_study_pages + '</span>" du <span class="text-blue-500 font-bold">Niveau ' + response.data.course_remark.level + '</span>';
            }
            else {
                document.getElementById('arab_daily_study_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_daily_review) {
                document.getElementById('arab_daily_review_div').classList.remove('d-none');

                document.getElementById('arab_daily_review_list').innerHTML = '"<span class="text-blue-500 font-bold">' + response.data.course_remark.arabic_review_pages + '</span>"';

            }
            else {
                document.getElementById('arab_daily_review_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_daily_home_work) {
                document.getElementById('arab_homework_div').classList.remove('d-none');
                document.getElementById('arab_homework_list').innerHTML = '"<span class="text-blue-500 font-bold">' + response.data.course_remark.arabic_homework_pages + '</span>"';
            }
            else {
                document.getElementById('arab_homework_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_next_session_exam) {
                document.getElementById('arab_next_session_exam_div').classList.remove('d-none');
            }
            else {
                document.getElementById('arab_next_session_exam_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_exam_result) {
                document.getElementById('arab_exam_result_div').classList.remove('d-none');
                document.getElementById('arab_result_level').innerText = response.data.course_remark.level;
                document.getElementById('arab_result_write').innerText = response.data.course_remark.arabic_exam_write_result;
                document.getElementById('arab_result_oral').innerText = response.data.course_remark.arabic_exam_read_result;
            }
            else {
                document.getElementById('arab_exam_result_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_success) {
                document.getElementById('success_div').classList.remove('d-none');
                document.getElementById('failed_div').classList.add('d-none');
                document.getElementById('arab_success_msg').classList.remove('d-none');
            }
            else if (response.data.course_remark.is_failed) {
                document.getElementById('failed_div').classList.remove('d-none');
                document.getElementById('success_div').classList.add('d-none');
                document.getElementById('arab_success_msg').classList.remove('d-none');
            }
            else {
                document.getElementById('success_div').classList.add('d-none');
                document.getElementById('failed_div').classList.add('d-none');
                document.getElementById('arab_success_msg').classList.add('d-none');
            }
        }
        else if (response.data.material == 'C') {
            if (response.data.course_remark.is_daily_study) {
                document.getElementById('quran_daily_study_div').classList.remove('d-none');
                document.getElementById('quran_daily_study_list').innerHTML = '';
                response.data.course_remark.daily_study_pages.forEach(element => {
                    var li = document.createElement('li');
                    li.innerHTML = 'Sourate : <span class="text-blue-500 font-bold">' + element.sourate + '</span> Verset : <span class="text-blue-500 font-bold">' + element.from + '</span> à <span class="text-blue-500 font-bold">' + element.to + '</span>';
                    document.getElementById('quran_daily_study_list').appendChild(li);
                });
            }
            else {
                document.getElementById('quran_daily_study_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_daily_review) {
                document.getElementById('quran_daily_review_div').classList.remove('d-none');
                document.getElementById('quran_daily_review_list').innerHTML = '';
                response.data.course_remark.daily_review_pages.forEach(element => {
                    var li = document.createElement('li');
                    li.innerHTML = 'Sourate : <span class="text-blue-500 font-bold">' + element.sourate + '</span> Verset : <span class="text-blue-500 font-bold">' + element.from + '</span> à <span class="text-blue-500 font-bold">' + element.to + '</span>';
                    document.getElementById('quran_daily_review_list').appendChild(li);
                });
            }
            else {
                document.getElementById('quran_daily_review_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_daily_home_work) {
                document.getElementById('quran_daily_homework_memorisation_div').classList.remove('d-none');

                document.getElementById('quran_daily_homework_memorisation_list').innerHTML = '';
                response.data.course_remark.daily_home_work_pages.forEach(element => {
                    var li = document.createElement('li');
                    li.innerHTML = 'Sourate : <span class="text-blue-500 font-bold">' + element.sourate + '</span> Verset : <span class="text-blue-500 font-bold">' + element.from + '</span> à <span class="text-blue-500 font-bold">' + element.to + '</span>';
                    document.getElementById('quran_daily_homework_memorisation_list').appendChild(li);
                });
            }
            else {
                document.getElementById('quran_daily_homework_memorisation_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_daily_home_work_review) {
                document.getElementById('quran_daily_homework_review_div').classList.remove('d-none');

                document.getElementById('quran_daily_homework_review_list').innerHTML = '';
                response.data.course_remark.daily_home_work_review_pages.forEach(element => {
                    var li = document.createElement('li');
                    li.innerHTML = 'Sourate : <span class="text-blue-500 font-bold">' + element.sourate + '</span> Verset : <span class="text-blue-500 font-bold">' + element.from + '</span> à <span class="text-blue-500 font-bold">' + element.to + '</span>';
                    document.getElementById('quran_daily_homework_review_list').appendChild(li);
                });
            }
            else {
                document.getElementById('quran_daily_homework_review_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_next_session_exam) {
                document.getElementById('quran_next_session_exam_div').classList.remove('d-none');

                document.getElementById('quran_next_session_exam_list').innerHTML = '';
                response.data.course_remark.exam_pages.forEach(element => {
                    var li = document.createElement('li');
                    li.innerHTML = 'Sourate : <span class="text-blue-500 font-bold">' + element.sourate + '</span> Verset : <span class="text-blue-500 font-bold">' + element.from + '</span> à <span class="text-blue-500 font-bold">' + element.to + '</span>';
                    document.getElementById('quran_next_session_exam_list').appendChild(li);
                });
            }
            else {
                document.getElementById('quran_next_session_exam_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_exam_result) {
                document.getElementById('quran_exam_result_div').classList.remove('d-none');
                document.getElementById('quran_exam_result').innerText = response.data.course_remark.quran_exam_result;
            }
            else {
                document.getElementById('quran_exam_result_div').classList.add('d-none');
            }

            if (response.data.course_remark.is_success) {
                document.getElementById('success_div').classList.remove('d-none');
                document.getElementById('failed_div').classList.add('d-none');
                document.getElementById('arab_success_msg').classList.add('d-none');
            }
            else if (response.data.course_remark.is_failed) {
                document.getElementById('failed_div').classList.remove('d-none');
                document.getElementById('success_div').classList.add('d-none');
                document.getElementById('arab_success_msg').classList.add('d-none');
            }
            else {
                document.getElementById('success_div').classList.add('d-none');
                document.getElementById('failed_div').classList.add('d-none');
                document.getElementById('arab_success_msg').classList.remove('d-none');
            }
        }
    }).catch(function (e) {
        console.error(e);
        el.classList.add("d-none-js");
    });
}

var OpenMonthlyReview = (modal_id, btn) => {
    data = btn.dataset;
    el = document.getElementById(modal_id);
    if (!el) {
        return;
    }

    el.classList.remove("d-none-js");

    // Instantiate new modal
    var modal = new Custombox.modal({
        content: {
            effect: "fadein",
            target: "#" + modal_id,
        },
    });

    // Open
    modal.open();

    document.addEventListener("custombox:overlay:close", function () {
        el.classList.add("d-none-js");
    });

    Axios.post(url_teacher_ajax_monthly_review_get, {
        monthly_review_id: data.monthly_review
    }).then(function (response) {
        if (response.data.material == 'C') {
            document.getElementById('quran_monthly_review_div').classList.remove('d-none');
            document.getElementById('quran_level').innerText = response.data.monthly_review.quran_level;
            document.getElementById('quran_concentration').innerText = response.data.monthly_review.quran_concentration;
            document.getElementById('quran_memorize').innerText = response.data.monthly_review.quran_memorize;
            document.getElementById('quran_review').innerText = response.data.monthly_review.quran_review;
            document.getElementById('quran_homework').innerText = response.data.monthly_review.quran_homework;
            document.getElementById('quran_absence').innerText = response.data.monthly_review.quran_absence;
        }
        else
            document.getElementById('quran_monthly_review_div').classList.add('d-none');

        if (response.data.material == 'A') {
            document.getElementById('arab_monthly_review_div').classList.remove('d-none');
            document.getElementById('arab_level').innerText = response.data.monthly_review.arab_level;
            document.getElementById('arab_concentration').innerText = response.data.monthly_review.arab_concentration;
            document.getElementById('arab_reactivity').innerText = response.data.monthly_review.arab_reactivity;
            document.getElementById('arab_homework').innerText = response.data.monthly_review.arab_homework;
            document.getElementById('arab_speak').innerText = response.data.monthly_review.arab_speak;
            document.getElementById('arab_speak_understanding').innerText = response.data.monthly_review.arab_speak_understanding;
            document.getElementById('arab_absence').innerText = response.data.monthly_review.arab_absence;
        }
        else
            document.getElementById('arab_monthly_review_div').classList.add('d-none');

        if (response.data.monthly_review.weak_internet == 1) {
            document.getElementById('weak_internet_div').classList.remove('d-none');
        }
        else {
            document.getElementById('weak_internet_div').classList.add('d-none');
        }
        document.getElementById('recommendation').innerText = response.data.monthly_review.teacher_recommendation.title;
        var recommendation_description = document.getElementById('recommendation_description');
        if (recommendation_description) {
            recommendation_description.innerText = response.data.monthly_review.teacher_recommendation.description;
        }
        var btn_edit = document.getElementById('btn_edit');
        if (btn_edit) {
            document.getElementById('btn_edit').addEventListener('click', function () {
                window.location = response.data.edit_link;
                // console.log('222');
            });
        }
    }).catch(function (e) {
        console.error(e);
        document.getElementById('level').innerText = '';
        document.getElementById('concentration').innerText = '';
        document.getElementById('reactivity').innerText = '';
        document.getElementById('homework').innerText = '';
        document.getElementById('speak').innerText = '';
        document.getElementById('understand').innerText = '';
        document.getElementById('recommendation').innerText = '';
        document.getElementById('weak_internet_div').classList.add('d-none');
        el.classList.add("d-none-js");
        var recommendation_description = document.getElementById('recommendation_description');
        if (!recommendation_description) {
            return;
        }
        recommendation_description.innerText = '';
    });
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

function AddRemarkSouratRow(container_div, input_name) {
    var id = makeid(10);
    var container = document.getElementById(container_div);
    // var newRow = document.getElementById(input_row_div)
    // var btn_html = `<button type="button" onclick="RemoveRemarkSouratRow('${id}')" class="bg-red-600 col-span-1 rounded hover:opacity-75 focus:outline-none text-md text-white font-rubikRegular hover:text-white py-2 px-2 border border-primary hover:border-transparent mr-2">X</button>`;
    // var div = document.createElement("div");
    // div.className = "mb-4 flex flex-wrap algin-items-center w-full";
    // div.id = id;
    // div.innerHTML = newRow.innerHTML + btn_html;
    // container.appendChild(div);

    var select_sourate = document.getElementById('select-sourate');

    var div_select = document.createElement('div');
    div_select.className = 'col-span-2';

    var select = document.createElement('select');
    select.className = select_sourate.className;
    select.classList.remove('d-none');
    select.innerHTML = select_sourate.innerHTML;
    select.name = input_name + '_sourate[]';
    div_select.appendChild(select);

    var div_from = document.createElement('div');
    div_from.className = 'col-span-1 md:w-1/3 px-3';
    var input_from = document.createElement('input');
    input_from.name = input_name + '_from[]';
    input_from.type = 'number';
    input_from.placeholder = 'Du';
    input_from.className = 'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500';
    div_from.appendChild(input_from);

    var div_to = document.createElement('div');
    div_to.className = 'col-span-1 md:w-1/3 px-3';
    var input_to = document.createElement('input');
    input_to.name = input_name + '_to[]';
    input_to.type = 'number';
    input_to.placeholder = 'A';
    input_to.className = 'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500';
    div_to.appendChild(input_to);

    var btn_remove_row = document.createElement('button');
    btn_remove_row.type = 'button';
    btn_remove_row.innerText = 'X';
    btn_remove_row.className = 'bg-red-600 col-span-1 rounded hover:opacity-75 focus:outline-none text-md text-white font-rubikRegular hover:text-white py-2 px-2 border border-primary hover:border-transparent mr-2';
    btn_remove_row.onclick = function () {
        RemoveRemarkSouratRow(id);
    };

    var div_row = document.createElement('div');
    div_row.className = 'mb-4 flex flex-wrap algin-items-center w-full';
    div_row.id = id;
    div_row.appendChild(div_select);
    div_row.appendChild(div_from);
    div_row.appendChild(div_to);
    div_row.appendChild(btn_remove_row);

    container.appendChild(div_row);
}

function RemoveRemarkSouratRow(row_id) {
    document.getElementById(row_id).remove();
}


module.exports = {
    load,
    open,
    OpenRemarkStudent,
    loadModalSections,
    showRemarkSection,
    openTeacher,
    cancel,
    save,
    SaveTeacher,
    OpenMonthlyReview,
    AddRemarkSouratRow,
    RemoveRemarkSouratRow
};
