require('./bootstrap');


let remark = require('./remark');
let note = require('./note');

window.noteOpen = note.open;
window.noteCancel = note.cancel;
window.noteSave = note.save;

window.remarkOpen = remark.open;
window.remarkOpenStudent = remark.OpenRemarkStudent;
window.remarkOpenTeacher = remark.openTeacher;
window.remarkloadModalSections = remark.loadModalSections;
window.remarkCancel = remark.cancel;
window.remarkSave = remark.save;
window.remarkshowRemarkSection = remark.showRemarkSection;
window.remarkSaveTeacher = remark.SaveTeacher;

window.monthlyreviewOpen = remark.OpenMonthlyReview;
window.AddRemarkSouratRow = remark.AddRemarkSouratRow;
window.RemoveRemarkSouratRow = remark.RemoveRemarkSouratRow;

// Windows loaded
(function () {
    'use strict';

    // Window

    // Page load
    window.addEventListener('load', function () {

        note.load();
        remark.load();

    });

    // fixed header
	window.onscroll = function() {fixedHeader()};

	var header = document.getElementById("header");
	var fixed = header.offsetTop;

	function fixedHeader() {
	  if (window.pageYOffset > fixed) {
	    header.classList.add("fixed");
	  } else {
	    header.classList.remove("fixed");
	  }
	}

})();
