'use strict';

const Custombox = require("exports-loader?Custombox!../../node_modules/custombox/dist/custombox.min.js");
//global.Custombox = Custombox;

let noteModal;
let el;
let data;

let load = () => {

}

let open = (container) => {

    data = event.target.dataset;
    el = document.getElementById(container);

    if (el) {

        document.querySelector('#student_note').value = data.studentNote ;
        document.querySelector('#note-student-name').innerHTML = data.studentName;
        el.classList.remove('d-none-js');

// Instantiate new modal
var modal = new Custombox.modal({
    content: {
      effect: 'fadein',
      target: '#modalNote',
    }
  });

  // Open
   modal.open();

        document.addEventListener('custombox:overlay:close', function() {
            // Overlay closed
            document.querySelector('#student_note').value = '' ;
            el.classList.add('d-none-js');
        });
    }
}


var cancel = () => {
    document.querySelector('#student_note').value = '' ;
    el.classList.add('d-none-js');
    Custombox.modal.close();
}

var save = () => {
    // Get note
    let el_note = document.querySelector('#student_note') ;
    let note = el_note.value;
    note = parseInt(note) ;
    if(!note) return false;
    if(note < 0 || note > 20 ) return false;

    var xhr = new XMLHttpRequest();
    xhr.onload = function () {

        if (xhr.status == 200) {

            let response = JSON.parse(xhr.response);
            console.log(response);
            Custombox.modal.close();
            el.classList.add('d-none-js');
            location.reload();
        } else {
            console.log('The request failed!');
            el.classList.add('d-none-js');
        }
    };
    xhr.open('POST', url_teacher_ajax_note_add);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('X-CSRF-TOKEN', csrf) ;
    xhr.send(JSON.stringify({
        sessionId: data.sessionId,
        studentId: data.studentId,
        programType: data.programType,
        note: note
    }));
}

module.exports = { load, open, cancel, save };
